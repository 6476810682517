import React from "react"
import { Link } from 'gatsby'
import Logo from "../../../../static/images/logo/logo.svg"
import Seo from "../../../components/seo"
import BookHeader from "../../../components/bookPageHeader"
import BookSlider from "../../../components/bookSlider"
import ReactBook from "../../../../static/images/commonImg/reactbook.svg"
import ReactBookTS from "../../../../static/images/commonImg/reactbook-ts.svg"
import ReactBook2 from "../../../../static/images/commonImg/reactbook2.svg"
import ReactBook2TS from "../../../../static/images/commonImg/reactbook2-ts.svg"
import NodeExpressBook from "../../../../static/images/commonImg/nodebook-intro.svg"
import NodeExpressBook2 from "../../../../static/images/commonImg/nodebook-advanced.svg"
//import NextBook2a from "../../../../static/images/commonImg/nextbook2-1.svg"
import NextBook2aTS from "../../../../static/images/commonImg/nextbook2-1ts.svg"
import MernBook from "../../../../static/images/commonImg/mern-book-1.svg"
import GatsbyBook from "../../../../static/images/commonImg/gatsbybook.svg"
import NextBook from "../../../../static/images/commonImg/nextbook.svg"
import JamstackBook from  "../../../../static/images/commonImg/jamstackbook.svg"
import vuebook from "../../../../static/images/commonImg/vuebook.svg"
import NuxtBook from "../../../../static/images/commonImg/nuxtbook.svg"
import JSBook from "../../../../static/images/commonImg/jsbook.svg"
import ProxyBook from "../../../../static/images/commonImg/proxyserver-book.png"
import NextFullAppFolder1 from "../../../../static/images/commonImg/nextbook-app-fullstack-1.svg"
import NextServerActionsBook from "../../../../static/images/commonImg/nextjs-server-actions.svg" 
import ReactMasterBook from "../../../../static/images/commonImg/react-master-book.svg" 
//import RegisterNewsLetterBody from "../../../components/registerNewsLetter"
import BookForm from "../../../components/bookForm"

//import AdNextFullstack from "./components/ad-next-fullstack-app-folder"

const generalData = {
    title: "TypeScriptマスター with React",
    updated: "2024/3/20",
    urlToApp: "https://react-typescript-app-meal-finder.netlify.app",
    urlToApp2: "https://react-typescript-app-best-match.netlify.app",
    urlToCode1: "https://github.com/mod728/book-code-es/tree/master/meal-finder-ts",
    urlToCode2: "https://github.com/mod728/book-code-es/tree/master/bestmatch-ts",
    reviewLink1: "",
    reviewLink2: ""
}

const booksData = [
    {oldPrice: "", newPrice: "", newRelease: "", title: "React全部入り：Reactマスター", img: ReactMasterBook, url: "https://monotein.base.shop/p/00001", description: "Reactを『なんとなく』は使える人が、2日で自信を持ってReactを書ける本！<br/><br/>JavaScriptの基礎の基礎、Reactの基本概念、そして応用までの全範囲をカバーした待望の解説書。<br/><br/>React入門に成功したビギナーが、たしかな理解とスキルを身につけて、自走できるようになる一冊。"},
    {oldPrice: "", newPrice: "", newRelease: "*New Release* 2024年11月発売", title: "Next.js Server Actionsでつくるフルスタックアプリ", img: NextServerActionsBook, url: "https://www.amazon.co.jp/dp/B0DMQLP8Z9", description: "Next.jsビギナー向けの入門書。<br/><br/>Next.jsとServer Actionsを使って、ログイン機能をもった本格的なアプリケーション開発の方法を紹介。<br/><br/>2024年10月にリリースされたばかりの最新のNext.jsバージョン15使用。"},
    {oldPrice: "", newPrice: "", newRelease: "*New Release* 2024年11月発売", title: "Next.jsでつくるフルスタックサイト　前編・後編", img: NextFullAppFolder1, url: "https://www.amazon.co.jp/dp/B0DRHF4KGM", description: "最新バージョンのNext.jsとAppフォルダを使って、バックエンドとフロントエンドの開発手法を紹介。<br/><br/>アプリ開発の基礎の基礎から、オンラインでの公開方法までの流れをすべて紹介している充実の内容です。"},
    {oldPrice: "", newPrice: "", newRelease: "", title: "MERNでつくるフルスタックアプリ（前編・後編）", img: MernBook , url: "https://www.amazon.co.jp/dp/B0BJN4H1NF", description: "Node.js、Express、React、MongoDBを使ってバックエンド、フロントエンド開発を行ない、アプリを作ります。<br/><br/>フルスタックアプリ開発の入門に最適な一冊。"},
]

const booksDataForSlider = [
    {newRelease: "", title: "", img: ReactBook, url: "https://www.amazon.co.jp/dp/B08XWMVX76", description: ""},
    {newRelease: "", title: "", img: ReactBookTS, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: ""},
    {newRelease: "", title: "", img: ReactBook2, url: "https://www.amazon.co.jp/dp/B09693ZJCV", description: ""},
    {newRelease: "", title: "", img: ReactBook2TS, url: "https://www.amazon.co.jp/dp/B09Q57Q9T1", description: ""},
    {newRelease: "", title: "", img: GatsbyBook, url: "https://www.amazon.co.jp/dp/B0BNQBDHFH", description: ""},
    {newRelease: "", title: "", img: JamstackBook, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: ""},
    {newRelease: "", title: "", img: NodeExpressBook, url: "https://www.amazon.co.jp/dp/B09HQZ1BW8", description: ""},
    {newRelease: "", title: "", img: NodeExpressBook2, url: "https://www.amazon.co.jp/dp/B09X56PSCQ", description: ""},
    {newRelease: "", title: "", img: JSBook, url: "https://www.amazon.co.jp/dp/B0C9C9SVFG", description: ""},
    {newRelease: "", title: "", img: vuebook, url: "https://www.amazon.co.jp/dp/B0B46QNTNL", description: ""},
    {newRelease: "", title: "", img: NuxtBook, url: "https://www.amazon.co.jp/dp/B0B5N8QJ2Y", description: ""},
    {newRelease: "", title: "", img: ProxyBook, url: "https://www.amazon.co.jp/dp/B0BBMQS28M", description: ""},
]

const links = [
    {
        chapterNum: "はじめに",
        data: [
            {name: "Note.js", url: "https://nodejs.org/en/download"},
            {name: "VS Code", url: "https://code.visualstudio.com/download"},
            {name: "エラー発生時の対処方法", url: "https://monotein.com/blog/how-to-find-and-fix-errors"}    
        ]
    },
    {
        chapterNum: 2,
        data: [
            {name: "見本アプリ（第2章）", url: "https://react-typescript-app-meal-finder.netlify.app"},
            {name: "TheMealDB", url: "https://www.themealdb.com/api.php"},
            {name: "Sushiのレシピデータ", url: "https://www.themealdb.com/api/json/v1/1/search.php?s=sushi"},
            {name: "Reactのイベントの型情報一覧", url: "https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/forms_and_events"},
            {name: "画像", url: "https://github.com/mod728/book-code-es/tree/master/meal-finder-ts/src/assets"},
            {name: "ファビコン画像", url: "https://github.com/mod728/book-code-es/tree/master/meal-finder-ts/public/favicon.svg"},
            {name: "CSS", url: "https://github.com/mod728/book-code-es/tree/master/meal-finder-ts/src/App.css"},
            {name: "Netlify Drop", url: "https://app.netlify.com/drop"}, 
            {name: "第2章完了時のソースコード", url: "https://github.com/mod728/book-code-es/tree/master/meal-finder-ts"},
        ]
    },
    {
        chapterNum: 4,
        data: [
            {name: "見本アプリ（第4章）", url: "https://react-typescript-app-best-match.netlify.app"},                
            {name: "API（シンプルな候補者情報）", url: "https://dummyjson.com/users?limit=30"},
            {name: "API（写真付きの候補者情報）", url: "https://randomuser.me/api/?results=12"},
            {name: "画像", url: "https://github.com/mod728/book-code-es/tree/master/bestmatch-ts/src/assets"},
            {name: "ファビコン画像", url: "https://github.com/mod728/book-code-es/blob/master/bestmatch-ts/public/favicon.svg"},
            {name: "CSS", url: "https://github.com/mod728/book-code-es/tree/master/meal-finder-ts/src/App.css"},
            {name: "Netlify Drop", url: "https://app.netlify.com/drop"}, 
            {name: "第4章完了時のソースコード", url: "https://github.com/mod728/book-code-es/tree/master/bestmatch-ts"},
        ]
    },
]

const TypeScriptMasterBookLinks = () => (
    <div className="container2">
        <Seo title={generalData.title} noindex={true} />
        <div><Link to="/blog"><img src={Logo} style={{width: "180px", margin: "auto", display: "block", paddingTop: "25px"}}/></Link></div>
        <div style={{textAlign: "center", marginTop: "16px"}}><a rel="noopener noreferrer" target="_blank" href="/blog">フロントエンド情報ブログ</a><span> || </span><a rel="noopener noreferrer" target="_blank" href="/business">著者について</a></div>
        <h3>『{generalData.title}』リンクページ</h3>
        <h4>更新日：<span>{generalData.updated}</span></h4>
        <BookHeader/>
        <ul>
            <h3 style={{backgroundColor: "#525252", display: "inline-block", color: "#fff", padding: "0 40px"}}>全般</h3>
            {generalData.urlToApp && <li>見本アプリ（第2章）：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToApp}>{generalData.urlToApp}</a></li>}
            {generalData.urlToApp2 && <li>見本アプリ（第4章）：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToApp2}>{generalData.urlToApp2}</a></li>}
            <li>ソースコード（第2章完了時）：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode1}>{generalData.urlToCode1}</a></li>
            <li>ソースコード（第4章完了時）：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode2}>{generalData.urlToCode2}</a></li>
{/*             <li><span className="amazon-review">Amazonでレビューする（前編）</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink1}>{generalData.reviewLink1}</a></li> */}
        </ul>

        <div className="book-wrapper">
            <div className="gridContainer">
                {booksData.map((x, index) => 
                    <div className="imgTextContainer" key={index}>
                        <a rel="noopener noreferrer" target="_blank" href={x.url}>
                            <img src={x.img} alt="cover"/>
                        </a>
                        <div>
                            {x.newRelease && <p className="new-release">{x.newRelease}</p>}
                            <br/>
                            {x.oldPrice && <p className="new-release-price">リリース記念特価（期間限定）<span>{x.oldPrice}円</span> ➡ {x.newPrice}円</p>}
                            <h3>『{x.title}』</h3>
                            <a rel="noopener noreferrer" target="_blank" href={x.url}><p>Amazonで見る</p></a>
                            <p dangerouslySetInnerHTML={{ __html: x.description }}/>
                        </div>
                    </div>
                )}
            </div>
            <BookSlider booksDataForSlider={booksDataForSlider}/>
        </div>

        <ul>
            {links.map((x, index) => 
                <div key={index}>
                    <h3>{typeof x.chapterNum === "string" ? x.chapterNum : `第${x.chapterNum}章`}</h3>
                    {x.data.map((y, index) => 
                        <li key={index}>{y.name}： <a rel="noopener noreferrer" target="_blank" href={y.url}>{y.url}</a></li>
                    )}
                </div>
            )}
        </ul>
        <div style={{marginTop: "90px" }}></div>
        <BookForm bookTitle="TypeScriptMaster"/>
    </div>
)

export default TypeScriptMasterBookLinks
